<template>
  <v-card class="unassign-team-dialog">
    <div class="unassign-team-dialog__header">
      <v-icon class="unassign-team-dialog__close" @click="$emit('onClose')">
        close
      </v-icon>
    </div>
    <div class="unassign-team-dialog__content">
      <div class="unassign-team-dialog-image">
        <team-avatar
          :src="team.avatar"
          class="unassign-team-dialog__logo"
          :size="68"
        />
      </div>
      <h3 class="unassign-team-dialog__title">
        {{ $t("leave_team_for_event", { team_name: team.name }) }}
      </h3>
      <div class="unassign-team-dialog__service">
        <base-button
          btn-type="tertiary-light"
          class="unassign-team-dialog__button unassign-team-dialog__cancel"
          @click="$emit('onClose')"
        >
          {{ $t("cancel") }}
        </base-button>
        <base-button
          btn-type="mem-primary"
          class="unassign-team-dialog__button unassign-team-dialog__leave"
          @click="submit"
        >
          {{ $t("leave") }}
        </base-button>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TeamAvatar from "@/components/TeamAvatar";
import BaseButton from "@/components/base/BaseButton";

export default {
  name: "UnassignTeamDialog",
  components: { BaseButton, TeamAvatar },
  computed: {
    ...mapGetters(["currentTicket"]),
    team() {
      return this.currentTicket?.team || {};
    },
  },
  methods: {
    ...mapActions(["handleToolbarNotification", "deleteTicketTeam"]),
    async submit() {
      try {
        this.$emit("onClose");
        await this.deleteTicketTeam(this.currentTicket?.barcode);
        this.handleToolbarNotification({
          type: "success",
          text: this.$t("leave_team_for_event_noty", {
            team_name: this.team?.name,
          }),
          isVisible: true,
        });
      } catch (error) {
        console.log(error, "");
        this.handleToolbarNotification({
          type: "warning",
          text: this.$t("server_error"),
          isVisible: true,
        });
      }
      setTimeout(() => {
        this.handleToolbarNotification({ isVisible: false });
      }, 3000);
    },
  },
};
</script>

<style lang="scss" scoped>
.unassign-team-dialog {
  padding: 24px;
  position: relative;
  border-radius: 10px !important;
  .unassign-team-dialog__header {
    display: flex;
    flex-direction: column;
    text-align: center;

    .unassign-team-dialog__close {
      color: black;
      position: absolute;
      cursor: pointer;
      top: 15px;
      right: 15px;
      width: 17px;
      height: 17px;

      &:hover,
      &:focus {
        &:after {
          opacity: 0;
        }
      }
    }
  }
  .unassign-team-dialog__content {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-top: 18px;
    .unassign-team-dialog-image {
      width: 100%;
      display: flex;
      justify-content: center;
      .unassign-team-dialog__logo {
        width: 68px;
        height: 68px;
      }
    }
    .unassign-team-dialog__title {
      padding-top: 32px;
      padding-bottom: 32px;
      font-size: 20px;
    }
    .unassign-team-dialog__service {
      display: flex;
      justify-content: center;
      .unassign-team-dialog__button {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        padding: 0 48px;
        max-height: 36px;

        & + .unassign-team-dialog__button {
          margin-left: 12px;
        }
      }
    }
  }

  @media screen and (max-width: 499px) {
    position: fixed;
    bottom: 8px;
    right: 0;
    left: 0;
    margin: 0 8px;
    max-width: calc(100vw - 16px);
    z-index: 999999;
  }
}
</style>
